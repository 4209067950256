
import _ from "lodash"
import { mapActions, mapMutations, mapState } from "vuex"
import ep from "~/api/endpoint"
import query_params from "~/api/query-params"
import {
  CHAT_ACTION_UPDATE_FIELDS,
  END_CHAT_UPDATE_FIELDS,
} from "../../constants/contact"

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    case_id: {
      type: String,
      default: "",
    },
  },
  watch: {
    visible: {
      handler(val) {
        if (val) {
          this.reason = "ignore"
          this.getCaseDetail()
        }
      },
      immediate: true,
    },
    current_contact: {
      deep: true,
      handler(next_val, prev_val) {
        const next_assignee_id = _.get(next_val, "assignee.member_id._id")
        const next_assign_to_id = _.get(next_val, "assign_to.member_id._id")
        const next_assign_to_team_id = _.get(next_val, "assign_to.team_id._id")
        const next_status = _.get(next_val, "status")

        const prev_assignee_id = _.get(prev_val, "assignee.member_id._id")
        const prev_assign_to_id = _.get(prev_val, "assign_to.member_id._id")
        const prev_assign_to_team_id = _.get(prev_val, "assign_to.team_id._id")
        const prev_status = _.get(prev_val, "status")

        if (
          next_assignee_id !== prev_assignee_id ||
          next_assign_to_id !== prev_assign_to_id ||
          next_assign_to_team_id !== prev_assign_to_team_id ||
          next_status !== prev_status
        ) {
          this.handleClose()
        }
      },
    },
  },
  data() {
    return {
      show_dialog: false,
      loading: false,
      reason: "ignore", // "ignore" | "spam"
      case_detail: {
        tags: [],
        assignee_member: null,
        involved_members: [],
      },
      form: {
        name: "",
        description: "",
      },
    }
  },
  computed: {
    ...mapState({
      oho_member: (state) => state.oho_member.member || {},
      case_list_current_contact: (state) => state.case_list_current_contact,
      current_contact: (state) => state.smartchat.current_contact,
    }),
    my_role() {
      return _.get(this.oho_member, "role")
    },
    is_show_spam_action() {
      const contact_status = _.get(this.current_contact, "status")
      const resolution_time_start_at = _.get(
        this.case_detail,
        "resolution_time_start_at"
      )

      return contact_status === "request" || !resolution_time_start_at
    },
    involved_member() {
      const member_list = _.cloneDeep(this.case_involved_member) || []
      let other_member = []
      let remove_duplicate_member = [
        ...new Map(
          member_list.map((item) => [item.member_id._id, item])
        ).values(),
      ]

      if (remove_duplicate_member.length > 0) {
        other_member = remove_duplicate_member.splice(3)
      }

      return {
        member_list: remove_duplicate_member,
        other_member: other_member,
      }
    },
    case_meta_data_update_permission() {
      return this.$permission.validate("case.metadata.update")
    },
    case_tag_add_permission() {
      return this.$permission.validate("case.tag.add")
    },
    case_tag_remove_permission() {
      return this.$permission.validate("case.tag.remove")
    },
    keyword_case_tag_create_permission() {
      return this.$permission.validate("keyword.case-tag.create")
    },
    keyword_case_tag_update_permission() {
      return this.$permission.validate("keyword.case-tag.update")
    },
    keyword_case_tag_remove_permission() {
      return this.$permission.validate("keyword.case-tag.remove")
    },
    assignee_member() {
      return _.get(this.case_detail, "assignee_member.member_id") || {}
    },
    assignee_team() {
      return _.get(this.case_detail, "assignee_member.team_id") || {}
    },
    case_involved_member() {
      return _.get(this.case_detail, "involved_members") || []
    },
    current_active_case() {
      return this.current_contact?.current_case?._id
    },
    is_active_case() {
      return this.current_active_case === this.case_id
    },
    active_case_param_value() {
      return this.is_active_case ? true : undefined
    },
    case_endpoint() {
      return this.is_active_case ? ep.active_case : ep.archived_case
    },
  },
  methods: {
    ...mapActions(["handleSetUpdateContactList", "handleUpdateContact"]),
    ...mapMutations([
      "setShowEndCaseDialog",
      "setCaseListCurrentContact",
      "setCurrentContact",
    ]),
    handleClose() {
      this.show_dialog = false
      this.setShowEndCaseDialog(false)
      this.$emit("update:visible", false)
    },
    async getCaseDetail() {
      try {
        this.loading = true
        const case_res = await this.$ohoMemberApi.$get(
          `${this.case_endpoint}/${this.case_id}`,
          {
            params: {
              "$populate[0][path]": "tags_migrate_objectid",
              "$populate[1][path]": "assignee_member.member_id",
              "$populate[1][select]": ["display_name", "role", "image_url"],
              "$populate[2][path]": "assignee_member.team_id",
              "$populate[2][select]": [
                "display_name",
                "type",
                "logo_url",
                "color_hex_code",
              ],
              "$populate[3][path]": "involved_members.member_id",
              "$populate[3][select]": ["display_name", "role", "image_url"],
              "$populate[3][populate][0][path]": "teams",
              "$populate[3][populate][0][select]": [
                "display_name",
                "type",
                "logo_url",
                "color_hex_code",
              ],
            },
          }
        )
        this.case_detail = {
          tags: [],
          assignee_member: null,
          involved_members: [],
          ...case_res,
        }
        this.form = {
          name: _.get(case_res, "name"),
          description: _.get(case_res, "description"),
        }
        this.show_dialog = true
        this.loading = false
      } catch (error) {
        this.loading = false
        this.setShowEndCaseDialog(false)
        this.$logger.error(_.get(error, "response.data"))
      }
    },
    async endChatNoCase(contact_id) {
      const case_detail = { ...this.form }
      try {
        const res = await this.$ohoMemberApi.$patch(
          `${ep.contact}/${contact_id}/close-chat/no-case`,
          { ...case_detail, end_case_reason: this.reason }
        )

        return res
      } catch (error) {
        await this.getAndUpdateContactById(contact_id)
        this.handleClose()
        this.$logger.error(_.get(error, "response.data"))

        return { ok: false, error: error }
      }
    },
    async endChatWithCase(contact_id) {
      const case_detail = { ...this.form }
      try {
        const res = await this.$ohoMemberApi.$patch(
          `${ep.contact}/${contact_id}/close-chat/end-case`,
          { ...case_detail }
        )
        return res
      } catch (error) {
        await this.getAndUpdateContactById(contact_id)
        this.handleClose()
        this.$logger.error(_.get(error, "response.data"))

        return { ok: false, error: error }
      }
    },
    async handleEndCase() {
      this.loading = true
      let res = null
      const contact_id = _.get(this.current_contact, "_id")

      try {
        if (this.is_show_spam_action) {
          res = await this.endChatNoCase(contact_id)
        } else {
          res = await this.endChatWithCase(contact_id)
        }

        if (_.get(res, "ok") === false) {
          const err = _.get(res, "error.response.data")
          const err_msg = this.$smartChatHelper.chatActionErrorMessage(err)
          this.$message.error(err_msg)
        } else {
          this.handleUpdateContact({
            event_message: {
              ...res,
              current_case: null,
            },
            update_fields: END_CHAT_UPDATE_FIELDS,
          })
          this.handleClose()

          // [Admin role] ถ้าเป็น agent เมื่อปฏิเสธให้ reset
          // หน้า smartchat เป็น state ที่ยังไม่ได้เลือกห้องแชท
          if (this.my_role === "agent") {
            this.resetCurrentSelectedRoom()
          }
        }
      } catch (error) {
        this.$logger.error(_.get(error, "response.data"))
        this.$message.error("เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง")
      }

      this.loading = false
    },
    async getAndUpdateContactById(contact_id) {
      try {
        const res = await this.$ohoMemberApi.$get(ep.contact_search, {
          params: {
            _id: contact_id,
            ...query_params.contact_default,
          },
        })

        const data = _.get(res, "data[0]")

        if (data) {
          this.handleUpdateContact({
            event_message: data,
            update_fields: CHAT_ACTION_UPDATE_FIELDS,
          })
        }
      } catch (error) {
        this.$logger.error(_.get(error, "response.data"))
      }
    },
    onAddTag(tag) {
      this.case_detail.tags_migrate_objectid.push(tag)
      this.updateCaseListTags()
    },
    onRemoveTag(tag) {
      const tag_index = this.case_detail.tags_migrate_objectid.findIndex(
        (d) => d._id == tag._id
      )
      if (tag_index < 0) return
      this.case_detail.tags_migrate_objectid.splice(tag_index, 1)
      this.updateCaseListTags()
    },
    async updateTag(body, tag, type) {
      try {
        await this.$ohoMemberApi.$patch(
          `${this.case_endpoint}/${this.case_detail._id}`,
          body
        )

        if (type === "add") {
          this.case_detail.tags.push(tag)
        } else {
          this.case_detail.tags.splice(this.case_detail.tags.indexOf(tag), 1)
        }
        this.updateCaseListTags()
      } catch (error) {
        this.$message.error(_.get(error, "response.data"))
      }
    },
    updateCaseListTags() {
      const case_index = _.findIndex(this.case_list_current_contact, {
        _id: this.case_id,
      })
      if (case_index >= 0) {
        this.case_list_current_contact[case_index].tags_migrate_objectid =
          this.case_detail.tags_migrate_objectid
        this.setCaseListCurrentContact(this.case_list_current_contact)
      }
    },
    onEditingTag(tag_data = {}) {
      const tag_index = this.case_detail.tags_migrate_objectid.findIndex(
        (d) => d._id == tag_data._id
      )
      if (tag_index < 0) return
      this.case_detail.tags_migrate_objectid.splice(tag_index, 1, tag_data)
    },
    onDeleteTag(tag_data) {
      const tag_index = this.case_detail.tags_migrate_objectid.findIndex(
        (d) => d._id == tag_data._id
      )
      if (tag_index < 0) return
      this.case_detail.tags_migrate_objectid.splice(tag_index, 1)
      this.updateCaseListTags()
    },
    // reset ห้องแชทให้เป็น state ที่ยังไม่ได้เลือกห้องแชท
    resetCurrentSelectedRoom() {
      this.setCurrentContact(null)
      const new_query = _.omit(this.$route.query, ["room"])
      this.$router.replace({ query: { ...new_query } })
    },
    get_other_involved_member_name(list) {
      return list.map((item) => item.member_id.display_name).join(", ")
    },
  },
}
